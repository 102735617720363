<template>
  <div class="exchange">
    <van-image
      v-if="storeImg"
      fit="cover"
      class="exchange-bg"
      :src="storeImg"
    />
    <van-image
      v-else
      fit="cover"
      class="exchange-bg"
      :src="require('../../static/images/v3/top-header.png')"
    />

    <div class="exchange-content">
      <div class="exchange-panel">
        <van-image
          fit="cover"
          class="exchange-panel-header"
          :src="require('../../static/images/v3/panel-header.png')"
        />
        <!-- 登录检验模块 -->
        <div>
          <van-row class="">
            <van-col span="24" class="input-mobile">
              <van-field
                v-model="mobile"
                class="mobile-field"
                maxlength="11"
                size="large"
                input-align="center"
                placeholder="输入中国移动手机号"
                @blur="onMobileBlur"
              />
            </van-col>
          </van-row>

          <van-row>
            <van-col span="24" class="exchange-submit">
              <van-button
                id="AUTHLOGIN"
                class="exchange-submit-btn"
                round
                color="linear-gradient(to right, #fab768, #fe6831)"
                @click="handleGet"
              >
                立即兑换
              </van-button>
            </van-col>
          </van-row>
        </div>

        <!-- 登录成功后的兑换模块 -->
        <div></div>
      </div>
      <div class="exchange-wxtip">
        可在微信->我->卡包->券和礼品卡查看已领取的立减金。
      </div>
    </div>

    <div class="exchange-rules">
      <div class="exchange-rules-panel">
        <div class="exchange-rules-panel-title">兑换规则</div>
        <div class="exchange-rules-info">
          *本兑换仅支持“移动用户”;仅支持移动消费积分兑换，移动营销积分不参与兑换。<br />
          1.客户可在“微信APP-我-卡包-券和礼品卡”中查看已获得的代金券;如若已经使用或过期，可在“微信APP-我-卡包-右上角三个点.常见问题-历史卡券列表”中查看。<br />
          2.使用微信付款时，2元券满2.01元自动抵扣，10元券满10.01元自动抵扣，20元券满20.01元自动抵扣，同卡种可多张叠加使用，叠加金额不得高于微信付款费用。<br />
          3.代金券有效期为兑换后60天内有效，请妥善安排再兑换。<br />
          4.代金券不可转发，过期作废，不再发放。<br />
          5.兑换成功后，扣除积分无法退回。<br />
          6.点击查询我的积分，即同意授权第三方获取您的手机号信息。<br />
          7.若在兑换页面一直转圈加载无反应，或者积分显示为0，代表不支持手机卡所在的归属地，移动用户现有以下归属地暂不支持积分兑换：广西、重庆、辽宁、河南、江西、云南、湖北、安徽，请知晓。<br />
          8.活动咨询热线：4001096138-9。<br />
          9.活动方对消费券的使用规则有最终解释权。<br />
        </div>
      </div>
    </div>

    <!-- 移动绑定页面 -->
    <van-popup
      v-model="showCmccBindPop"
      position="center"
      closeable
      :close-on-click-overlay="true"
      :style="{
        minHeight: '260px',
        width: '90%',
        backgroundColor: 'transparent',
      }"
      round
    >
      <div class="cmcc-popup">
        <!-- 验证码输入 -->
        <div v-if="unShowAgreement">
          <div class="cmcc-popup-header">
            <img
              class="cmcc-popup-header-cmccimg"
              :src="require('../../static/images/v4/ex-cmcc-bind.png')"
              alt=""
            />
            <div class="cmcc-popup-header-title">
              <img
                class="cmcc-popup-header-title-lineL"
                :src="require('../../static/images/v4/lineL.png')"
                alt=""
              />
              <div class="cmcc-popup-header-title-lab">您正在绑定畅由平台</div>
              <img
                class="cmcc-popup-header-title-lineR"
                :src="require('../../static/images/v4/lineR.png')"
                alt=""
              />
            </div>
          </div>

          <div class="cmcc-popup-section">
            <div class="cmcc-popup-field" style="margin-top: 30px">
              <img
                class="cmcc-popup-field-icon"
                :src="require('../../static/images/v4/phone.png')"
              />
              <van-field
                class="cmcc-popup-field-input"
                v-model="cmccBindPhone"
                readonly
              />
            </div>

            <div class="cmcc-popup-field-code">
              <div class="cmcc-popup-field">
                <img
                  class="cmcc-popup-field-icon"
                  :src="require('../../static/images/v4/code.png')"
                />
                <van-field
                  class="cmcc-popup-field-input"
                  v-model="cmccBindCode"
                  placeholder="请输入短信验证码"
                  maxlength="6"
                />
              </div>
              <div>
                <div
                  class="cmcc-popup-field-code-btn"
                  @click.stop="handleSendSMS"
                >
                  {{ countdownLab }}
                </div>
              </div>
            </div>

            <div class="cmcc-popup-agreement">
              <img
                v-if="agreeText"
                class="cmcc-popup-agreement-icon"
                :src="require('../../static/images/v4/do.png')"
                alt=""
                @click="agreeText = false"
              />
              <img
                v-else
                class="cmcc-popup-agreement-icon"
                :src="require('../../static/images/v4/undo.png')"
                alt=""
                @click="agreeText = true"
              />
              <div
                class="cmcc-popup-agreement-lab"
                @click="agreeText = !agreeText"
              >
                勾选代表您已阅读并同意
              </div>
              <div
                class="cmcc-popup-agreement-href"
                @click="unShowAgreement = false"
              >
                《移动授权用户协议》
              </div>
            </div>

            <div class="cmcc-popup-submit" @click.stop="handleConfirmBind">
              确认提交
            </div>
          </div>
        </div>

        <!-- 协议展示 -->
        <div v-else class="cmcc-popup-content">
          <div
            class="cmcc-popup-content-header"
            @click="unShowAgreement = true"
          >
            <img
              class="cmcc-popup-content-back"
              :src="require('../../static/images/v4/back.png')"
              alt=""
            />
            移动用户授权协议
          </div>
          <div class="cmcc-popup-content-text">
            <article>
              <h3>尊敬的用户：</h3>
              <h3 class="t-indent">
                您即将通过本登录页面使用中国移动账户登录上海分互链信息技术有限公司所有的“分互链积分平台”，如果您无法认同如下内容，请您点击“返回”并拒绝授权：
              </h3>
              <p>
                <i>1、</i>
                <span
                  >如您点击“继续”，即视为您同意和授权中国移动向上海分互链信息技术有限公司提供账户数据接口以使上海分互链信息技术有限公司可以调用您在中国移动网站（www.10086.cn）的注册账户的登录信息，便于您直接使用您在中国移动网站的注册信息登录“分互链积分平台”。</span
                >
              </p>
              <p>
                <i>2、</i>
                <span
                  >如您点击“继续”，即视为您同意及授权上海分互链信息技术有限公司通过数据接口实时读取您在中国移动的消费积分的剩余数量
                  。</span
                >
              </p>
              <p>
                <i>3、</i>
                <span
                  >如您点击“继续”，则表明您已明确知晓上海分互链信息技术有限公司及“分互链积分平台”并非中国移动的关联公司或由中国移动运营，您使用“分互链积分平台”或上海分互链信息技术有限公司提供的其他服务的行为均与中国移动无关，您也不能就使用中国移动网站注册信息登录及使用“分互链积分平台”的后果要求中国移动承担任何责任。</span
                >
              </p>
              <p>
                <i>4、</i>
                <span
                  >上海分互链信息技术有限公司及“分互链积分平台”所提供的商品或服务的质量和品质由上海分互联信息技术有限公司独立负责，上海分互链信息技术有限公司自行对提供该等商品或服务引起的后果向您承担全部责任。</span
                >
              </p>
              <p>
                <i>5、</i>
                <span
                  >中国移动郑重提醒您保管好您在中国移动网站的注册登录信息，切勿向任何人透露您的账号、密码等相关信息。除非得到您的同意及授权，中国移动不会向任何第三方透露您的任何信息。</span
                >
              </p>
              <p>
                <i>6、</i>
                <span
                  >中国移动郑重提醒您启用杀毒软件和安全措施，以防范各类网络风险。</span
                >
              </p>
            </article>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Row, Col, Field, Button, Image, Popup } from "vant";
import {
  authLogin,
  queryCmccBalanceBind,
  getBindMsgCode,
  bindInfoCmcc,
  getStoreInfo
} from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Button.name]: Button,
    [Image.name]: Image,
    [Popup.name]: Popup,
  },
  data() {
    return {
      mobile: "",
      code: "",
      storeImg: null,

      // CMCC Bind
      showCmccBindPop: false,
      cmccBindPhone: "",
      cmccBindCode: "",
      unShowAgreement: true,
      agreeText: true,
      isCountDown: false,
      countdownLab: "获取验证码",
      timer: null,
      tokenId: null,
      validateToken: null,
    };
  },
  created() {
    window.merId = this.$route.query.merId;
    window.storeId = this.$route.query.storeId;
    window.channelCode = this.$route.query.channelCode;

    this.getCode();
    this.getStoreInfoData()
  },
  watch: {
    showCmccBindPop(n, o) {
      if (!n) {
        clearInterval(this.timer)
        this.cmccBindCode = ''
      }
    }
  },
  methods: {
    getCode() {
      let currentUrl = window.location.href;

      const appId = "wxac158a56464862b3";
      this.code = this.getUrlCode().code;
      if (this.code == null || this.code == "") {
        // https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect
        // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodeURIComponent(currentUrl)}&response_type=code&scope=snsapi_base&state=FastLJJ#wechat_redirect`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(currentUrl)}&response_type=code&scope=snsapi_base&state=WXFastLJJ#wechat_redirect`;
      }
    },
    // 获取门店信息
    getStoreInfoData() {
      getStoreInfo().then(res => {
        if (res.storeImg && res.storeImg.length > 0) {
          this.storeImg = res.storeImg
        }
      })
    },

    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    onMobileBlur() {
      let lastMobile = localStorage.getItem("ljj_mobile") || null;
      if (lastMobile != this.mobile) {
        this.showCmccBindPop = false;
      }
    },
    /**
     * 绑定移动业务 >>>
     */
    // 开始倒计时
    startCountDown() {
      const _t = this;
      let initVal = 60;
      this.timer = setInterval(() => {
        if (initVal <= 0) {
          _t.isCountDown = false;
          _t.countdownLab = "获取验证码";

          clearInterval(_t.timer);
          return;
        }

        initVal -= 1;
        _t.countdownLab = initVal + "s";
      }, 1000);
    },
    // 发送绑定验证码
    handleSendSMS() {
      if (this.mobile.length != 11) {
        this.$toast("请输入合法的手机号");
        return;
      }

      if (this.isCountDown) {
        return;
      }

      getBindMsgCode({
        tokenId: this.tokenId,
        validateToken: this.validateToken,
        channelCode: this.$route.query.channelCode,
      })
        .then((res) => {
          this.tokenId = res.tokenId || null;
          this.validateToken = res.validateToken || null;

          if (res.retVal == "888") {
            this.showCmccBindPop = true;
            this.isCountDown = true;
            this.startCountDown();
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.tokenId = err.result.tokenId || null;
          this.validateToken = err.result.validateToken || null;

          _this.$toast(err.result.msg || "短信发送失败");
        });
    },

    // 确认兑换（检查是否需要绑定）
    handleGet() {
      if (this.mobile.length != 11) {
        this.$toast("请输入合法的手机号");
        return;
      }

      if (this.code.length <= 0) {
        this.$toast("请在微信内重新打开");
        return;
      }

      const _this = this;
      authLogin({
        mobile: this.mobile,
        channelCode: this.$route.query.channelCode,
      }).then((res) => {
        console.log(res);
        this.cmccBindPhone = this.mobile;
        localStorage.setItem("ljj_login_token", res.loginToken);
        localStorage.setItem("ljj_mobile", res.mobile);

        let data = {
          fingerprint: window.blackbox,
          sessionid: window.sessionId,
          channelCode: _this.$route.query.channelCode,
        };

        if (this.$route.query.id) {
          data.transCode = this.$route.query.id;
        }

        if (this.$route.query.ddback) {
          data.ddback = this.$route.query.ddback;
        }

        queryCmccBalanceBind(data)
          .then((qres) => {
            // {points: '2563'}

            let query = _this.$route.query;
            if (this.$route.query.channelCode) {
              query.channelCode = _this.$route.query.channelCode;
            }

            if (_this.$route.query.merId) {
              query.merId = _this.$route.query.merId;
            }

            if (_this.$route.query.storeId) {
              query.storeId = _this.$route.query.storeId;
            }

            if (_this.$route.query.id) {
              query.id = _this.$route.query.id;
            }

            if (_this.$route.query.ddback) {
              query.ddback = _this.$route.query.ddback;
            }

            query.code = _this.code;

            _this.$router.push({
              path: "/info",
              query,
            });
          })
          .catch((err) => {
            // 1008611 这个返回码是 获取绑定地址失败的
            // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"1分钟内只允许发送一次验证码！请稍后重试","tokenId":"1分钟内只允许发送一次验证码！请稍后重试","retVal":"803","validateToken":"T4cYpLmmKMQeg+vK+DNJX79ydmPZeUspx+Ecq/6oGOKZvmjmubc2KwcL2oiEmLmnUarRIzixvC4hskbGqDBsBw=="},"timestamp":1670933717770}
            // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"系统繁忙，请稍候重试","tokenId":"系统繁忙，请稍候重试","retVal":"801","validateToken":"LOq3jn0rXbqQg/SQJ1eIjRAa3x1+1I/NE8hXKM3UMgByLsE6WszMU6VWXSz70Yja1xw/NPvlnk8ovZL6C45TlQ=="},"timestamp":1670933709962}
            // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"短信发送成功","tokenId":"短信发送成功","retVal":"888","validateToken":"tArfz0ulLLeePw1xXtwJCsIdbYoDhtyOzSaNdP5I5fSPhqDULB/4Mz6Kx68+LnTFXcxDRVL0DRA/Fz2srARTyw=="},"timestamp":1670933672486}
            _this.validateToken = err.result.validateToken || null;
            _this.tokenId = err.result.tokenId || null;

            if (err.result.retVal == "888") {
              _this.showCmccBindPop = true;
              _this.isCountDown = true;
              _this.startCountDown();
            }

            _this.$toast(err.result.msg);
          });
      });
    },
    // 提交绑定
    handleConfirmBind() {
      if (this.mobile.length != 11) {
        this.$toast("请输入合法的手机号");
        return;
      }

      if (this.cmccBindCode.length != 6) {
        this.$toast("请输入验证码");
        return;
      }

      const _this = this;
      let data = {
        smsCode: _this.cmccBindCode,
        tokenId: _this.tokenId,
        validateToken: _this.validateToken,
        channelCode: _this.$route.query.channelCode,
      };

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id;
      }

      if (this.$route.query.ddback) {
        data.ddback = this.$route.query.ddback;
      }

      bindInfoCmcc(data)
        .then((res) => {
          console.log("res >>>>>>> ", res);
          if (res.retVal == "888") {
            let query = _this.$route.query;
            if (this.$route.query.channelCode) {
              query.channelCode = _this.$route.query.channelCode;
            }

            if (_this.$route.query.merId) {
              query.merId = _this.$route.query.merId;
            }

            if (_this.$route.query.storeId) {
              query.storeId = _this.$route.query.storeId;
            }

            if (_this.$route.query.id) {
              query.id = _this.$route.query.id;
            }

            if (_this.$route.query.ddback) {
              query.ddback = _this.$route.query.ddback;
            }

            query.code = _this.code;
            
            _this.$router.push({
              path: "/info",
              query,
            });
          } else {
            _this.validateToken = res.validateToken || null;
            _this.tokenId = res.tokenId || null;
            _this.$toast(res.msg);
          }
        })
        .catch((err) => {
          _this.$toast(err.message);
        });
    },

    /**
     * 绑定移动业务 <<<
     */

    /**
     * 绑定成功后的逻辑请求 >>>
     */

    /**
     * 绑定成功后的逻辑请求 <<<
     */
  },
};
</script>

<style lang="less" scoped>
.exchange {
  position: relative;
  min-height: 100vh;
  padding-bottom: 10px;
  background-color: #ffd9ae;

  &-bg {
    width: 100%;
    min-height: 300px;
    // height: 100vh;
  }

  &-content {
    position: relative;
    // background-color: rgba(255, 255, 255, 0.5);
    margin: 0px 20px;
    padding: 0px 0px 10px;
    border-radius: 10px;
    z-index: 99;
  }

  &-wxtip {
    padding: 8px 6px 0;
    font-weight: 600;
    font-size: 14px;
    color: #df5214;
  }

  &-guize {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    &-tip {
      width: 85%;
      margin-bottom: 10px;
    }
  }

  &-rules {
    position: relative;
    background: linear-gradient(to right, #fdfffe, #fbf9e0);
    margin: 10px 20px 20px;
    padding: 5px 5px 10px;
    border-radius: 10px;
    z-index: 99;

    &-panel {
      border-radius: 10px;
      padding: 10px 10px 10px;

      &-title {
        width: 100%;
        font-weight: 600;
        color: #df5214;
        text-align: center;
      }
    }

    &-info {
      padding-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #ef8b29;
      line-height: 160%;

      &-tip {
        font-size: 13px;
        color: #666;
      }

      &-img {
        width: 96%;
        padding-left: 2%;
        padding-top: 10px;
        padding-bottom: 0px;
      }
    }
  }

  &-panel {
    background-color: #fbf3d4;
    border-radius: 10px;
    padding: 30px 0;

    &-header {
      position: absolute;
      width: 200px;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-tip {
    width: 150px;
    margin-left: 50%;
    padding-bottom: 10px;
    transform: translateX(-50%);
  }

  &-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &-submit-btn {
    margin-top: 10px;
    width: 80%;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 2px 3px 5px 0px #fe6831;
    // padding-top: 10px;
    // -webkit-animation-name: scaleDraw; /*关键帧名称*/
    // -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
    // -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
    // -webkit-animation-duration: 2s; /*动画所花费的时间*/
  }
}

.input-mobile {
  width: 90%;
  height: 55px;
  margin: 0 5%;
  // border-radius: 30px;
  // box-shadow: 0px 2px 8px #ddd;
  overflow: hidden;
}

.mobile-field {
  height: 55px;
  line-height: 28px;
  font-size: 18px;
  border-radius: 30px;
  font-weight: 600;
  border: 2px solid #fe6831;
}

/**
  CMCC弹窗
*/
.cmcc-popup {
  width: 100%;
  background-color: #fff;
  min-height: 460px;

  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    &-cmccimg {
      width: 220px;
    }

    &-title {
      display: flex;
      align-items: center;
      margin-top: 25px;

      &-lab {
        font-weight: 500;
        color: #333;
      }

      &-lineL {
        height: 2px;
        width: 50px;
        margin-right: 10px;
      }

      &-lineR {
        height: 2px;
        width: 50px;
        margin-left: 10px;
      }
    }
  }

  &-section {
    padding: 0 30px;
  }

  &-field {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b3b3b3;

    &-icon {
      height: 22px;
      width: 20px;
    }

    &-input {
      color: red;
    }
  }

  &-field-code {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    &-btn {
      font-size: 14px;
      width: 120px;
      height: 40px;
      margin-left: 10px;
      color: #fff;
      background-color: #0ca0f0;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
    }
  }

  &-agreement {
    margin-top: 15px;
    color: #333;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 500;

    &-icon {
      width: 15px;
      height: 15px;
    }

    &-lab {
      padding-left: 5px;
    }

    &-href {
      color: #015bff;
    }
  }

  &-submit {
    margin-top: 50px;
    background-color: #0ca0f0;
    height: 44px;
    line-height: 44px;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
  }

  &-content {
    font-size: 15px;
    font-weight: 450;
    color: #666666;
    padding: 15px;

    &-header {
      position: relative;
      font-size: 17px;
      background-color: #fcfcfc;
      height: 44px;
      line-height: 44px;
      text-align: center;
    }

    &-back {
      position: absolute;
      left: 10px;
      height: 22px;
      top: 11px;
    }

    &-text {
      margin-top: 20px;
    }
  }
}

.sms-mobile {
  width: 90%;
  height: 55px;
  margin: 10px 5% 0;
  border-radius: 30px;
  // box-shadow: 0px 2px 8px #ddd;
  overflow: hidden;
}

.sms-field {
  height: 55px;
  line-height: 15px;
  font-size: 18px;
  border-radius: 30px;
  font-weight: 600;
  border: 1px solid #006bd9;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

/deep/ .cmcc-popup-field .van-field__control {
  color: #333;
  font-size: 16px;
}

/deep/ .cmcc-popup-field-code .van-button {
  height: 44px;
}

h3,
i,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.t-indent {
  text-indent: 30px;
}
</style>
